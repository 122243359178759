.customInput {
    position: relative;
    max-width: 448px;
    min-width: 256px;
    width: 100%;
    margin-bottom: 24px !important;

    &__label {
        position: absolute;
        left: 5px;
        top: 3px;
        padding: 8px 18px; //calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * .5);
        margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * .5);
        background: pink;
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        transition: transform 120ms ease-in;
        font-family: "Poppins";
        font-size: 16px;
        line-height: 25.6px;
        letter-spacing: -0.32px;
    }

    &__field {
        box-sizing: border-box;
        display: block;
        border-radius: 12px !important;
        width: 100%;
        padding: 12px 20px; //calc(var(--size-bezel) * 1.5) var(--size-bezel);
        background: transparent;
        border-radius: var(--size-radius);
        height: 48px;
        font-family: "Poppins-Medium";
        font-size: 16px;
        line-height: 25.6px;
        letter-spacing: -0.32px;

        &:focus,
        &:not(:placeholder-shown) {
            &+.customInput__label {
                transform: translate(0.75rem, -45%) scale(0.9);
                padding: 0px 20px;
                padding-left: 9px;
                top: 0
            }
        }

        &:hover {
            box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.07);
        }
    }

    .icon-label {
        transform: translate(0.75rem, -45%) scale(0.9);
        padding: 0px 20px;
        padding-left: 9px;
        top: 0
    }
}

// checkbox
// Use class 'customCheckbox' if there is no label text and if there is label text, please use 'customCheckbox label'

.customCheckbox {
    display: block;
}

.customCheckbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.customCheckbox label {
    position: relative;
    cursor: pointer;
}

.customCheckbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
    border-radius: 4px;
}

.customCheckbox input:checked+label:before {
    border: 2px solid #177CDA;
}

.customCheckbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 9px;
    width: 6px;
    height: 13px;
    border: solid #177CDA;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.customCheckbox.label input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 9px;
    width: 6px;
    height: 13px;
    border: solid #177CDA;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.toggle-wrapper {
    position: relative;
    width: 60px;
    border-radius: 25px;
    @media (max-width: 767px) {
        width: fit-content;
    }
    .toggle {
        opacity: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        z-index: 3;
        position: absolute;
        top: 0;
        cursor: pointer;
    }
    .toggle-body {
        display: block;
        width: 40px;
        height: 20px;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 25px;
        background: #E5E5E5;
        z-index: 1;
    }
    .toggle:checked+.toggle-body {
        background: #177CDA;
    }
    .thumb {
        position: absolute;
        border-radius: 15px;
        padding: 0;
        top: 8px;
        left: 4px;
        width: 14px;
        height: 14px;
        background-color: #fff;
        background: -webkit-radial-gradient(10px 10px, circle cover, #fff);
        z-index: 2;
        margin-top: -5px !important;
    }
    .toggle:checked+.toggle-body .thumb {
        left: 20px;
    }
    .toggle:checked+.toggle-body .thumb:after {
        // content: url('/assets/images/sidebar/light.png');
        width: 100%;
        height: 100%;
        top: -1px;
        left: -15px;
        border-radius: 15px;
        position: absolute;
    }
    .toggle+.toggle-body .thumb:after {
        // content: url('/assets/images/sidebar/light.png');
        width: 100%;
        height: 100%;
        top: -2px;
        left: 20px;
        border-radius: 15px;
        position: absolute;
    }
}