.radioButton{
    display: flex ;
    align-items: center;
    input[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
    }
    input[type="radio"]::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
    }

    input[type="radio"]:checked{
        border: 0.15em solid #177CDA !important;
    }

    input[type="radio"]:checked::before {
        transform: scale(1);
        background-color:#177CDA !important;
    }
    // input[type="radio"]:focus {
    //     outline: max(2px, 0.15em) solid currentColor;
    //     outline-offset: max(2px, 0.15em);
    // }
}