@import './variables';

.ngSelectBox {
    position: relative;

    &__label {
        position: absolute;
        top: -11px;
        left: 15px;
        padding: 0px 5px;
        font-family: $font-primary;
        font-size: 16px;
        line-height: 25.6px;
        letter-spacing: -0.32px;
    }

    &__noLabel {
        display: none;
    }
}

.ng-select.ng-select-single .ng-select-container {
    height: 48px;
    padding: 0px 10px;
}

.ng-select {
    .ng-select-container {
        background-color: transparent !important;
        border-radius: 8px;
        font-family: $font-secondary;
        font-size: 16px;
        line-height: 25.6px;
        letter-spacing: -0.32px;

        &:hover {
            box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.07);
        }

        .ng-value-container .ng-placeholder {
            font-family: $font-primary;
            font-size: 16px;
            line-height: 25.6px;
            letter-spacing: -0.32px;
        }

        .ng-arrow-wrapper {
            padding-right: 28px;

            .ng-arrow {
                border-width: 0;
                background-image: url('../images/icons/back-btn.svg');
                width: 20px;
                height: 20px;
                background-repeat: no-repeat;
                background-position-y: 75%;
                background-position-x: 100%;
            }
        }
    }
}

.ng-select.ng-select-focused>.ng-select-container {
    box-shadow: unset !important;
}

.ng-dropdown-panel.ng-select-bottom {
    background-color: transparent !important;
    border-radius: 0px 0px 8px 8px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
}