@import '_variables.scss';

// Buttons

.primary-button {
    min-width: 160px;
    padding: 0px 16px;
    height: 44px;
    // background: #177CDA;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    color: #FFFFFF;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    transition: all 0.3s ease !important;
    border: 2px solid transparent;

    &:active {
        transform: scale(0.96);
    }

    &:disabled {
        cursor: not-allowed;
        background-color: #000000;
        opacity: 0.3;

        &:hover {
            border: none !important;
            box-shadow: unset !important;
        }
    }

    &.danger {
        background-color: $high !important;
    }

    &.success {
        background-color: $low !important;
    }

}

.hero-button {
    min-width: 160px;
    padding: 0px 20px;
    height: 44px;
    background: $new-cta;
    border-radius: 2px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    color: #FFFFFF;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    transition: all 0.3s ease !important;

    &:hover {
        box-shadow: 0px 0px 6px 0px rgba(50, 169, 238, 0.70);
    }

    &:active {
        transform: scale(0.96);
    }

    &:disabled {
        cursor: not-allowed;
        // background-color: #000000;
        opacity: 0.7;

        // &:hover {
        //     border: none !important;
        //     box-shadow: unset !important;
        // }
    }
}
.hero-button-sm {
    min-width: 160px;
    height: 36px;
    padding: 0px 16px;
    border-radius: 12px;
    background: $new-cta;
    border: 2px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    color: #FFFFFF;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    transition: all 0.3s ease !important;

    &:hover {
        box-shadow: 0px 0px 6px 0px rgba(50, 169, 238, 0.70);
    }

    &:active {
        transform: scale(0.96);
    }

    &:disabled {
        cursor: not-allowed;
        // background-color: #000000;
        opacity: 0.7;

        // &:hover {
        //     border: none !important;
        //     box-shadow: unset !important;
        // }
    }
}
.secondary-button {
    min-width: 160px;
    height: 48px;
    padding: 0px 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;

    &:hover {
        box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.07);
    }

    &:disabled {
        cursor: not-allowed;
        background-color: #000000;
        opacity: 0.3;

        &:hover {
            border: none !important;
            box-shadow: unset !important;
        }
    }
}

// Filter and Download Button

.filter-button {
    min-width: 120px;
    height: 48px;
    padding: 0px 16px;
    border-radius: 8px;
    cursor: pointer;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    background-color: transparent;
    color: #177CDA;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.filled-outline-button,
.filled-outline-button-vblue,
.filled-outline-button-vorange
 {
    min-width: 60px;
    height: 36px;
    padding: 0px 16px;
    border-radius: 12px;
    cursor: pointer;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    color: #177CDA;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
}

.active-btn-vblue {
    box-shadow: 0px 0px 9px rgba(0, 117, 255, 0.5) !important;
    border: 2px solid #0075FF !important;
}

.text-transparent-button {
    min-width: 60px;
    height: 36px;
    padding: 0px 16px;
    border-radius: 12px;
    border: none !important;
    cursor: pointer;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    color: #177CDA;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}


// chips

.primary-chips {
    min-width: 120px;
    height: 48px;
    padding: 0px 16px;
    border-radius: 8px;
    font-family: "Poppins-Medium" !important;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    span {
        cursor: pointer;
        padding-left: 32px;
        height: 48px;
        display: flex;
        align-items: center;
    }
}


// Dialog Buttons

.dialog-outline-buttons {
    height: 48px;
    background-color: transparent;
    border-radius: 0px;
    border: none;
    cursor: pointer;
    font-family: $font-semi-bold;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
}

.dialog-fill-buttons {
    height: 48px;
    color: #FFFFFF;
    font-family: $font-semi-bold;
    font-size: 16px;
    border-radius: 0px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    border: none;
    cursor: pointer;
}

.badge-simple {
    min-width: 60px;
    height: 36px;
    padding: 0px 16px;
    border-radius: 12px;
    cursor: pointer;
    // font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    color: #fff;
}