.customSelect {
    position: relative;
    max-width: 448px;
    min-width: 256px;
    width: 100%;
    height: 48px;
    border-radius: 12px;
    margin-bottom: 24px;
    // &:after {
    //     position: absolute;
    //     top: 18px;
    //     right: 10px;
    //     /* Styling the down arrow */
    //     width: 0;
    //     height: 0;
    //     padding: 0;
    //     content: '';
    //     border-left: 6px solid transparent;
    //     border-right: 6px solid transparent;
    //     border-top: 6px solid rgba(0, 0, 0, 0.12);
    //     pointer-events: none;
    // }

    .select-text {
        position: relative;
        font-family: inherit;
        background-color: transparent;
        width: 96%;
        padding: 10px 10px 10px 0;
        border-radius: 0;
        border: none;
        border-bottom: none; //1px solid rgba(0,0,0, 0.12);
        font-family: "Poppins-Medium";
        font-size: 16px;
        line-height: 25.6px;
        letter-spacing: -0.32px;
        border-radius: 10px;
        height: 48px;
        padding: 12px 20px;

        &:focus {
            outline: none;
            border-bottom: none; // 1px solid rgba(0,0,0, 0);

            &+.select-label {
                transform: translate(0.75rem, -45%) scale(0.9);
                color: #85868F;
                padding-left: 9px;
                top: 0;
            }
        }
    }

    .select-label {
        position: absolute;
        left: 0;
        top: 5px;
        padding: 4px 20px; //calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * .5);
        margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * .5);
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        transition: transform 120ms ease-in;
        font-family: "Poppins";
        font-size: 16px;
        line-height: 25.6px;
        letter-spacing: -0.32px;
    }

    .select-text:focus~.select-label,
    .labelFloat {
        //, .select-text:valid ~ .select-label {
        transform: translate(0.75rem, -45%) scale(0.9);
        color: #85868F;
        padding: 0px 20px;
        padding-left: 9px;
        top: 0;
    }

}

.rowPageSelect {
    background-color: transparent;
    height: 36px;
    min-width: 100px;
    padding: 0px 16px;
    border-radius: 12px;

    select {
        border: none;
        width: 100%;
        padding: 4px;
        background-color: transparent;
    }
}