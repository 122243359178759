/* =============
   Buttons
============= */
.btn {
  border-radius: 2px;
  padding: 6px 14px;
  font-size: $base-font-size;
  box-shadow: none !important;
}
.btn-group-lg>.btn, .btn-lg {
  padding: 10px 16px !important;
  font-size: 16px;
}
.btn-group-sm>.btn, .btn-sm {
  padding: 5px 10px !important;
  font-size: 12px;
}

.btn-group .btn+.btn, .btn-group .btn+.btn-group, .btn-group .btn-group+.btn, .btn-group .btn-group+.btn-group {
  margin-left: 0;
}
.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: 0 0 0 100px rgba(0,0,0,.1) inset;
  box-shadow: 0 0 0 100px rgba(0,0,0,.1) inset;
}
.btn-custom,.btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger, .btn-inverse, .btn-purple, .btn-pink {
  color: $white !important;
}

.btn-custom {
  background-color: $custom;
  border-color: $custom;
}
.btn-custom:hover, .btn-custom:focus, .btn-custom:active, .btn-custom.active,
.btn-custom.focus, .btn-custom:active, .btn-custom:focus, .btn-custom:hover,
.open > .dropdown-toggle.btn-custom  {
  background-color: darken($custom, 5%) !important;
  border: 1px solid darken($custom, 5%) !important;
}

.btn-primary{
  background-color: $primary !important;
  border: 1px solid $primary !important;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active,
.btn-primary.focus, .btn-primary:active, .btn-primary:focus, .btn-primary:hover,
.open > .dropdown-toggle.btn-primary  {
  background-color: darken($primary, 5%) !important;
  border: 1px solid darken($primary, 5%) !important;
}

.btn-success {
  background-color: $success !important;
  border: 1px solid $success !important;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success.focus, .btn-success:active, .btn-success:focus, .btn-success:hover, .open > .dropdown-toggle.btn-success {
  background-color: darken($success, 5%) !important;
  border: 1px solid darken($success, 5%) !important;
}

.btn-info {
  background-color: $info !important;
  border: 1px solid $info !important;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info.focus, .btn-info:active, .btn-info:focus, .btn-info:hover, .open > .dropdown-toggle.btn-info {
  background-color: darken($info, 5%) !important;
  border: 1px solid darken($info, 5%) !important;
}

.btn-warning {
  background-color: $warning !important;
  border: 1px solid $warning !important;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning.focus, .btn-warning:active, .btn-warning:focus, .btn-warning:hover, .open > .dropdown-toggle.btn-warning {
  background-color: darken($warning, 5%) !important;
  border: 1px solid darken($warning, 5%) !important;
}

.btn-danger {
  background-color: $danger !important;
  border: 1px solid $danger !important;
}
.btn-danger:active, .btn-danger:focus, .btn-danger:hover, .btn-danger.active, .btn-danger.focus, .btn-danger:active, .btn-danger:focus, .btn-danger:hover, .open > .dropdown-toggle.btn-danger {
  background-color: darken($danger, 5%) !important;
  border: 1px solid darken($danger, 5%) !important;
}

.btn-inverse {
  background-color: $inverse !important;
  border: 1px solid $inverse !important;
}
.btn-inverse:hover, .btn-inverse:focus, .btn-inverse:active, .btn-inverse.active, .btn-inverse.focus, .btn-inverse:active, .btn-inverse:focus, .btn-inverse:hover, .open > .dropdown-toggle.btn-inverse {
  background-color: lighten($inverse, 5%) !important;
  border: 1px solid lighten($inverse, 5%) !important;
}

.btn-purple {
  background-color: $purple !important;
  border: 1px solid $purple !important;
}
.btn-purple:hover, .btn-purple:focus, .btn-purple:active {
  background-color: darken($purple, 5%) !important;
  border: 1px solid darken($purple, 5%) !important;
}

.btn-pink {
  background-color: $pink !important;
  border: 1px solid $pink !important;
}
.btn-pink:hover, .btn-pink:focus, .btn-pink:active {
  background-color: darken($pink, 5%) !important;
  border: 1px solid darken($pink, 5%) !important;
}


.btn-bordred {
  border-bottom: 3px solid transparent;
}

.btn-bordred.btn-secondary {
  background-color: $secondary;
  border-bottom: 2px solid darken($secondary, 7%) !important;
}
.btn-bordred.btn-custom {
  background-color: $custom;
  border-bottom: 2px solid darken($custom, 7%) !important;
}

.btn-bordred.btn-primary {
  border-bottom: 2px solid darken($primary, 7%) !important;
}

.btn-bordred.btn-success {
  border-bottom: 2px solid darken($success, 7%) !important;
}

.btn-bordred.btn-info {
  border-bottom: 2px solid darken($info, 7%) !important;
}

.btn-bordred.btn-warning {
  border-bottom: 2px solid darken($warning, 10%) !important;
}

.btn-bordred.btn-danger {
  border-bottom: 2px solid darken($danger, 10%) !important;
}

.btn-bordred.btn-inverse {
  border-bottom: 2px solid darken($inverse, 20%) !important;
}

.btn-bordred.btn-purple {
  border-bottom: 2px solid darken($purple, 7%) !important;
}

.btn-bordred.btn-pink {
  border-bottom: 2px solid darken($pink, 7%) !important;
}

.btn-rounded {
  border-radius: 2em;
  padding: 6px 18px;
}


.btn-trans.btn-custom {
  border: 1px solid rgba($custom, 0.1) !important;
  background-color: rgba($custom, 0.15) !important;
  color: $custom !important;
}

.btn-trans.btn-secondary {
  border: 1px solid rgba($secondary, 0.1) !important;
  background-color: rgba($secondary, 0.15) !important;
  color: $secondary !important;
}

.btn-trans.btn-primary {
  border: 1px solid rgba($primary, 0.1) !important;
  background-color: rgba($primary, 0.15) !important;
  color: $primary !important;
}

.btn-trans.btn-success {
  border: 1px solid rgba($success, 0.1) !important;
  background-color: rgba($success, 0.15) !important;
  color: $success !important;
}

.btn-trans.btn-info {
  border: 1px solid rgba($info, 0.1) !important;
  background-color: rgba($info, 0.15) !important;
  color: $info !important;
}

.btn-trans.btn-warning {
  border: 1px solid rgba($warning, 0.1) !important;
  background-color: rgba($warning, 0.15) !important;
  color: $warning !important;
}

.btn-trans.btn-danger {
  border: 1px solid rgba($danger, 0.1) !important;
  background-color: rgba($danger, 0.15) !important;
  color: $danger !important;
}

.btn-trans.btn-inverse {
  border: 1px solid rgba($light, 0.1) !important;
  background-color: rgba($light, 0.15) !important;
  color: $muted !important;
}

.btn-trans.btn-purple {
  border: 1px solid rgba($purple, 0.1) !important;
  background-color: rgba($purple, 0.15) !important;
  color: $purple !important;
}

.btn-trans.btn-pink {
  border: 1px solid rgba($pink, 0.1) !important;
  background-color: rgba($pink, 0.15) !important;
  color: $pink !important;
}

/* File Upload */
.fileupload {
  overflow: hidden;
  position: relative;
}
.fileupload input.upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}
/* Social Buttons */
.btn-facebook {
  color: $white !important;
  background-color: #3b5998 !important;
}
.btn-twitter {
  color: $white !important;
  background-color: #00aced !important;
}
.btn-linkedin {
  color: $white !important;
  background-color: #007bb6 !important;
}
.btn-dribbble {
  color: $white !important;
  background-color: #ea4c89 !important;
}
.btn-googleplus {
  color: $white !important;
  background-color: #dd4b39 !important;
}
.btn-instagram {
  color: $white !important;
  background-color: #517fa4 !important;
}
.btn-pinterest {
  color: $white !important;
  background-color: #cb2027 !important;
}
.btn-dropbox {
  color: $white !important;
  background-color: #007ee5 !important;
}
.btn-flickr {
  color: $white !important;
  background-color: #ff0084 !important;
}
.btn-tumblr {
  color: $white !important;
  background-color: #32506d !important;
}
.btn-skype {
  color: $white !important;
  background-color: #00aff0 !important;
}
.btn-youtube {
  color: $white !important;
  background-color: #bb0000 !important;
}
.btn-github {
  color: $white !important;
  background-color: #171515 !important;
}

/* =============
   Checkbox and Radios
============= */

.checkbox {
  padding-left: 5px;
  label {
    display: inline-block;
    padding-left: 5px;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;

    &::before {
      -o-transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      background-color: darken($dark,10%);
      border-radius: 3px;
      border: 2px solid lighten($dark,5%);
      content: "";
      display: inline-block;
      height: 17px;
      left: 0;
      margin-left: -17px;
      position: absolute;
      transition: 0.3s ease-in-out;
      width: 17px;
      outline: none !important;
    }
    &::after {
      color: $muted;
      display: inline-block;
      font-size: 11px;
      height: 16px;
      left: 0;
      margin-left: -17px;
      padding-left: 3px;
      padding-top: 1px;
      position: absolute;
      top: 0;
      width: 16px;
    }
  }
  input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none !important;

    &:disabled + label {
      opacity: 0.65;
    }
  }
  input[type="checkbox"]:focus + label {
    &::before {
      outline-offset: -2px;
      outline: none;
    }
  }
  input[type="checkbox"]:checked + label {
    &::after {
      content: "\f00c";
      font-family: 'FontAwesome';
    }
  }
  input[type="checkbox"]:disabled + label {
    &::before {
      background-color: $light;
      cursor: not-allowed;
    }
  }
}

.checkbox.checkbox-circle {
  label {
    &::before {
      border-radius: 50%;
    }
  }
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox.checkbox-single {
  input {
    height: 18px;
    width: 18px;
    position: absolute;
  }
  label {
    height: 18px;
    width: 18px;

    &:before {
      margin-left: 0;
    }
    &:after {
      margin-left: 0;
    }
  }
}


.checkbox-custom {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $custom;
      border-color: $custom;
    }
    &::after {
      color: $white;
    }
  }
}

.checkbox-primary {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $primary;
      border-color: $primary;
    }
    &::after {
      color: $white;
    }
  }
}

.checkbox-danger {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $danger;
      border-color: $danger;
    }
    &::after {
      color: $white;
    }
  }
}

.checkbox-info {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $info;
      border-color: $info;
    }
    &::after {
      color: $white;
    }
  }
}

.checkbox-warning {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $warning;
      border-color: $warning;
    }
    &::after {
      color: $white;
    }
  }
}

.checkbox-success {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $success;
      border-color: $success;
    }
    &::after {
      color: $white;
    }
  }
}

.checkbox-purple {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $purple;
      border-color: $purple;
    }
    &::after {
      color: $white;
    }
  }
}

.checkbox-pink {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $pink;
      border-color: $pink;
    }
    &::after {
      color: $white;
    }
  }
}

.checkbox-inverse {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $white;
      border-color: $white;
    }
    &::after {
      color: $dark;
    }
  }
}

/* Radios */

.radio {
  //padding-left: 20px;
  label {
    display: inline-block;
    padding-left: 5px;
    position: relative;
    font-weight: 400;
    font-size: 13px;
    &::before {
      -o-transition: border 0.5s ease-in-out;
      -webkit-transition: border 0.5s ease-in-out;
      background-color: darken($dark,10%);
      border-radius: 50%;
      border: 2px solid lighten($dark,5%);
      content: "";
      display: inline-block;
      height: 17px;
      left: 0;
      margin-left: -20px;
      position: absolute;
      transition: border 0.5s ease-in-out;
      width: 17px;
      outline: none !important;
    }
    &::after {
      -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -webkit-transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      background-color: $muted;
      border-radius: 50%;
      content: " ";
      display: inline-block;
      height: 11px;
      left: 3px;
      margin-left: -20px;
      position: absolute;
      top: 3px;
      transform: scale(0, 0);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      width: 11px;
    }
  }
  input[type="radio"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none !important;
    &:disabled + label {
      opacity: 0.65;
    }
  }
  input[type="radio"]:focus + label {
    &::before {
      outline-offset: -2px;
      outline: 5px auto -webkit-focus-ring-color;
      outline: thin dotted;
    }
  }
  input[type="radio"]:checked + label {
    &::after {
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  input[type="radio"]:disabled + label {
    &::before {
      cursor: not-allowed;
    }
  }
}

.radio.radio-inline {
  margin-top: 0;
}

.radio.radio-single {
  label {
    height: 17px;
  }
}


.radio-custom {
  input[type="radio"] + label {
    &::after {
      background-color: $custom;
    }
  }
  input[type="radio"]:checked + label {
    &::before {
      border-color: $custom;
    }
    &::after {
      background-color: $custom;
    }
  }
}

.radio-primary {
  input[type="radio"] + label {
    &::after {
      background-color: $primary;
    }
  }
  input[type="radio"]:checked + label {
    &::before {
      border-color: $primary;
    }
    &::after {
      background-color: $primary;
    }
  }
}

.radio-danger {
  input[type="radio"] + label {
    &::after {
      background-color: $danger;
    }
  }
  input[type="radio"]:checked + label {
    &::before {
      border-color: $danger;
    }
    &::after {
      background-color: $danger;
    }
  }
}

.radio-info {
  input[type="radio"] + label {
    &::after {
      background-color: $info;
    }
  }
  input[type="radio"]:checked + label {
    &::before {
      border-color: $info;
    }
    &::after {
      background-color: $info;
    }
  }
}

.radio-warning {
  input[type="radio"] + label {
    &::after {
      background-color: $warning;
    }
  }
  input[type="radio"]:checked + label {
    &::before {
      border-color: $warning;
    }
    &::after {
      background-color: $warning;
    }
  }
}

.radio-success {
  input[type="radio"] + label {
    &::after {
      background-color: $success;
    }
  }
  input[type="radio"]:checked + label {
    &::before {
      border-color: $success;
    }
    &::after {
      background-color: $success;
    }
  }
}

.radio-purple {
  input[type="radio"] + label {
    &::after {
      background-color: $purple;
    }
  }
  input[type="radio"]:checked + label {
    &::before {
      border-color: $purple;
    }
    &::after {
      background-color: $purple;
    }
  }
}

.radio-pink {
  input[type="radio"] + label {
    &::after {
      background-color: $pink;
    }
  }
  input[type="radio"]:checked + label {
    &::before {
      border-color: $pink;
    }
    &::after {
      background-color: $pink;
    }
  }
}


/* X Editable */
.editable-checklist label{
  display: block;
}




/* =============
   Cards
============= */
.card {
  background-color: inherit;
  border-color: darken($dark,5%);
}
.card-title,.card-header {
  margin-top: 0;
  border: none !important;
}
.card-header {
  background-color: inherit;
}


// Draggable cards
.card-draggable-placeholder {
  border: 2px dashed rgba($dark, 0.5);
  margin-bottom: 16px;
  background-color: rgba($dark, 0.08);
}


/* =============
   Progressbars
============= */

.progress {
  -webkit-box-shadow: none !important;
  background-color: darken($dark,5%);
  box-shadow: none !important;
  height: 10px;
  margin-bottom: 18px;
  overflow: hidden;
}

.progress-bar {
  box-shadow: none;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
}

.progress.progress-sm {
  height: 5px !important;
  .progress-bar {
    font-size: 8px;
    line-height: 5px;
  }
}

.progress.progress-md {
  height: 15px !important;
  .progress-bar {
    font-size: 10.8px;
    line-height: 14.4px;
  }
}

.progress.progress-lg {
  height: 20px !important;
  .progress-bar {
    font-size: 12px;
    line-height: 20px;
  }
}

.progress-bar-primary {
  background-color: $primary;
}

.progress-bar-success {
  background-color: $success;
}

.progress-bar-info {
  background-color: $info;
}

.progress-bar-warning {
  background-color: $warning;
}

.progress-bar-danger {
  background-color: $danger;
}

.progress-bar-inverse {
  background-color: $light;
}

.progress-bar-purple {
  background-color: $purple;
}

.progress-bar-pink {
  background-color: $pink;
}
.progress-bar-custom {
  background-color: $custom;
}

/* Custom progressbars */
.progress-bar-custom-alt {
  background-color: rgba($custom,0.2);
}
.progress-bar-primary-alt {
  background-color: rgba($primary,0.2);
}
.progress-bar-success-alt {
  background-color: rgba($success,0.2);
}
.progress-bar-info-alt {
  background-color: rgba($info,0.2);
}
.progress-bar-warning-alt {
  background-color: rgba($warning,0.2);
}
.progress-bar-danger-alt {
  background-color: rgba($danger,0.2);
}
.progress-bar-purple-alt {
  background-color: rgba($purple,0.2);
}
.progress-bar-inverse-alt {
  background-color: rgba($inverse,0.2);
}
.progress-bar-pink-alt {
  background-color: rgba($pink,0.2);
}

.progress-animated {
  -webkit-animation-duration: 5s;
  -webkit-animation-name: animationProgress;
  -webkit-transition: 5s all;
  animation-duration: 5s;
  animation-name: animationProgress;
  transition: 5s all;
}

/* Progressbar Vertical */
.progress-vertical {
    min-height: 250px;
    height: 250px;
    width: 10px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;

    .progress-bar {
      width: 100%;
   }
}
.progress-vertical-bottom{
    min-height: 250px;
    height: 250px;
    position: relative;
    width: 10px;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;

    .progress-bar {
      width: 100%;
      position: absolute;
      bottom: 0;
   }
}

.progress-vertical.progress-sm,.progress-vertical-bottom.progress-sm {
  width: 5px !important;
  .progress-bar {
    font-size: 8px;
    line-height: 5px;
  }
}

.progress-vertical.progress-md,.progress-vertical-bottom.progress-md {
  width: 15px !important;
  .progress-bar {
    font-size: 10.8px;
    line-height: 14.4px;
  }
}

.progress-vertical.progress-lg,.progress-vertical-bottom.progress-lg {
  width: 20px !important;
  .progress-bar {
    font-size: 12px;
    line-height: 20px;
  }
}



/* =============
   Tables
============= */

.table {
  margin-bottom: 10px;
}

// .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th,
// .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td ,
// .table > thead > tr > th, .table-bordered{
//     border-color: none;
// }
// .table-bordered > thead > tr > th,
// .table-bordered > tbody > tr > th,
// .table-bordered > tfoot > tr > th,
// .table-bordered > thead > tr > td,
// .table-bordered > tbody > tr > td,
// .table-bordered > tfoot > tr > td {
//   border-color: none;
// }
// .table > thead > tr > th {
//     vertical-align: bottom;
//     border-color: none;
// }

// .table-bordered th, .table-bordered td {
//   border-color: none !important;
// }

// .table-dark td, .table-dark th, .table-dark thead th {
//   border-color: none !important;
// }

table.focus-on {
  tbody {
    tr.focused {
      th {
        background-color: $custom;
        color: $white;
      }
      td {
        background-color: $custom;
        color: $white;
      }
    }
  }
}

// Key Tables
table.dataTable th.focus,table.dataTable td.focus{
  outline: 2px solid $custom !important;
  outline-offset: -1px;
  background-color: rgba($custom,0.15);
}

.table th {
  padding: 0.75rem 0.25rem;
}
.table tbody+tbody {
  border-top: unset;
}

// Multi select table
table.dataTable tbody>tr.selected, table.dataTable tbody>tr>.selected {
  background-color: $custom;
}

/* Responsive Table */

.table-rep-plugin {
  .dropdown-menu li.checkbox-row {
    padding: 2px 15px !important;
  }

  .table-responsive {
    border: none !important;
  }
  tbody {
    th {
      font-size: 14px;
      font-weight: normal;
    }
  }
  .checkbox-row {
    padding-left: 40px;

    label {
      display: inline-block;
      padding-left: 5px;
      position: relative;
      &::before {
        -o-transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        background-color: $white;
        border-radius: 3px;
        border: 1px solid $muted;
        content: "";
        display: inline-block;
        height: 17px;
        left: 0;
        margin-left: -20px;
        position: absolute;
        transition: 0.3s ease-in-out;
        width: 17px;
        outline: none !important;
      }
      &::after {
        color: $light3;
        display: inline-block;
        font-size: 11px;
        height: 16px;
        left: 0;
        margin-left: -20px;
        padding-left: 3px;
        padding-top: 1px;
        position: absolute;
        top: -1px;
        width: 16px;
      }
    }
    input[type="checkbox"] {
      cursor: pointer;
      opacity: 0;
      z-index: 1;
      outline: none !important;

      &:disabled + label {
        opacity: 0.65;
      }
    }
    input[type="checkbox"]:focus + label {
      &::before {
        outline-offset: -2px;
        outline: none;
      }
    }
    input[type="checkbox"]:checked + label {
      &::after {
        content: "\f00c";
        font-family: 'FontAwesome';
      }
    }
    input[type="checkbox"]:disabled + label {
      &::before {
        background-color: $light;
        cursor: not-allowed;
      }
    }
    input[type="checkbox"]:checked + label {
      &::before {
        background-color: $white;
        border-color: $custom;
      }
      &::after {
        color: $custom;
      }
    }
  }
  table.focus-on tbody tr.focused th, table.focus-on tbody tr.focused td,
  .sticky-table-header{
    background: $custom;
    color: $white;
    border-color: $custom;
  }
  .sticky-table-header {
    top: 70px !important;
  }
  .btn-default {
    background-color: $white;
    border: 1px solid rgba($dark, 0.3);
  }
  .btn-group.pull-right {
    float: right;
    .dropdown-menu {
      left: auto;
      right: 0;
    }
  }
}

.table-rep-plugin .btn-toolbar {
  display: block;
}

.modal-block {
  background: transparent;
  margin: 40px auto;
  max-width: 600px;
  padding: 0;
  position: relative;
  text-align: left;
}


/* editable table */
#datatable-editable {
  .actions {
    a {
      padding: 5px;
    }
  }
  .form-control {
    background-color: $white;
    width: 100%;
  }
  .fa-trash-o {
    color: $danger;
  }
  .fa-times {
    color: $danger;
  }
  .fa-pencil {
    color: $success;
  }
  .fa-save {
    color: $success;
  }
}

/* Data tables */
#datatable {
  td {
    font-weight: normal;
  }
}
.paginate_button {
  display: inline-block !important;
}

div.dataTables_paginate {
  ul.pagination {
    margin-top: 30px;
  }
}

div.dataTables_info {
  padding-top: 38px;
}

.dt-buttons {
  float: left;
}
div#datatable-buttons_info {
  float: left;
}
/* Responsive data table */
table.dataTable.dtr-inline.collapsed>tbody>tr>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th:first-child:before{
    box-shadow: 0 0 3px fade($dark,20%);
    background-color: $primary;
}
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th:first-child:before {
  background-color: $danger;
}
/* Fixed table */
.fixedHeader-floating {
  top: 70px !important;
}

/* Key Table */
table.dataTable th.focus, table.dataTable td.focus {
  outline: 3px solid $custom !important;
  outline-offset: -1px
}

/* Tablesaw */
.tablesaw thead {
  background: darken($dark,5%);
  background-image: none;
  border: none;
}
.tablesaw thead th {
  text-shadow: none;
  letter-spacing: 0.06em;
}
.tablesaw thead tr:first-child th {
  padding-top: 1.1em;
  padding-bottom: 0.9em;
}
.tablesaw thead tr:first-child th {
  font-weight: 600;
  font-family: inherit;
  border: none;
  color: $white;
}

.tablesaw-bar .tablesaw-toolbar select,.tablesaw-enhanced .tablesaw-bar .btn.btn-select {
  color: $muted;
}

.tablesaw td, .tablesaw tbody th {
  font-size: inherit;
  line-height: inherit;
  padding: 10px !important;
}
.tablesaw-stack tbody tr ,.tablesaw tbody tr{
  border-bottom: none;
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after, .tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
  font-family: FontAwesome;
  font-size: 10px;
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-ascending button:after {
  content: "\f176";
}
.tablesaw-sortable .tablesaw-sortable-head.tablesaw-sortable-descending button:after {
  content: "\f175";
}
.tablesaw-bar .btn-select.btn-small:after, .tablesaw-bar .btn-select.btn-micro:after {
  font-size: 8px;
  padding-right: 10px;
}
.tablesaw-swipe .tablesaw-cell-persist {
  box-shadow: none;
  border-color: darken($dark,7%);
}
.tablesaw-enhanced .tablesaw-bar .btn {
  text-shadow: none;
  background-image: none;
}
.tablesaw-enhanced .tablesaw-bar .btn.btn-select {
  &:hover {
    background: $white;
  }
}
.tablesaw-enhanced .tablesaw-bar .btn:hover, .tablesaw-enhanced .tablesaw-bar .btn:focus,.tablesaw-enhanced .tablesaw-bar .btn:active {
  color: $primary !important;
  background-color: $light5;
  outline: none !important;
  box-shadow: none !important;
  background-image: none;
}
.tablesaw-toolbar label {
  color: $white;
  font-weight: 700;
}

/* =============
   Widgets
============= */

.widget-chart-1 {
  .widget-chart-box-1 {
    float: left;
  }
  .widget-detail-1 {
    text-align: right;
    margin-left: 80px;
    min-height: 77px;
  }
}

.widget-box-2 {
  .widget-detail-2 {
    text-align: right;

    .badge {
      padding: 5px 10px;
    }
  }
}

.widget-user {
  min-height: 112px;

  img {
    height: 72px;
    float: left;
  }

  .wid-u-info {
    margin-left: 90px;

    p,h4 {
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}


/* Inbox-widget */

.inbox-widget {
  .inbox-item {
    border-bottom: 1px solid rgba($white,0.1);
    overflow: hidden;
    padding: 10px 0;
    position: relative;
    .inbox-item-img {
      display: block;
      float: left;
      margin-right: 15px;
      width: 40px;
    }
    img {
      width: 40px;
    }
    .inbox-item-author {
      color: rgba($white, 0.7);
      display: block;
      margin: 0;
    }
    .inbox-item-text {
      color: $muted;
      display: block;
      font-size: 12px;
      margin: 0;
    }
    .inbox-item-date {
      color: $muted;
      font-size: 11px;
      position: absolute;
      right: 7px;
      top: 2px;
    }
  }
}

.kyc-select-container{
  min-width: 160px;
  height: 48px;
  padding: 0px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-family: "Poppins" !important;
  font-size: 16px;
  line-height: 25.6px;
  letter-spacing: -0.32px;
}

.half-arc {
  position: relative;
  width: 200px;
  height: 100px;
  border-top-left-radius: 120px;
  border-top-right-radius: 120px;
  border-bottom: 0;
  background: #2F3D46;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicator{
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  border-radius: 50%;
  transition: transform .5s ease-in-out;
  z-index: 1;
  transform: rotate(270deg);
}

.half-arc:after {
  content: "";
  position: absolute;
  display: block;
  // Change the background color to themed color
  background-color: transparent;
  z-index: 2;
  width: calc(100% - 32px);
  height: calc(200% - 32px);
  border-radius: 50%;
  top: 16px;
  left: 16px;
}

.semi-circle-indiactor {
  text-align: center;
}

.indicator-value {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

.progress-bar-indicator-container {
  background: rgba(255,255,255,0.1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  // padding: 0 5px;
  display: flex;
  height: 12px;
  width: 500px;
}

.progress-bar-value {
  box-shadow: 0 10px 40px -10px $progress;
  border-radius: 100px;
  background: $progress;
  height: 12px;
  min-width: 1% !important;
}

.match-value{
  color: $progress;
  font-size: 16px;
}

.gradient-bg{
  background: linear-gradient(131deg, #04DFB8 0%, #4198FF 100%);

}