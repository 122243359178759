/*
Template Name: Adminto Dashboard
Author: CoderThemes
Email: coderthemes$gmail.com
File: Menu
*/

$menu-width : 250px;

.topbar {

  left: 0px;
  position: fixed;
  right: 0;
  top: 0px;
  // z-index: 999;
  z-index: 11;

  .topbar-left {
    background: $custom;
    float: left;
    text-align: center;
    height: 71px;
    position: relative;
    width: $menu-width;
    z-index: 1;

    .logo {
      // line-height: 70px;
    }
  }
}

.navbar-default {
  background-color: $custom;
  border-radius: 0px;
  border: none;
  margin-bottom: 0px;
  padding: 0px 0px;
  // box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);

  .navbar-left {
    li {
      padding-right: 10px;
    }
  }

  .button-menu-mobile {
    display: none;
  }

  .nav-link {
    padding: 0;
    // line-height: 70px;
    color: rgba($dark, 0.8);
  }

  .dropdown-toggle {
    &:after {
      content: initial;
    }
  }

  .menu-left {
    overflow: hidden;
  }

  .topbar-right-menu {
    li {
      float: left;
    }
  }
}

.logo {
  color: $white !important;
  font-size: 32px;

  i {
    display: none;
  }

  span {
    span {
      color: $white;
    }
  }
}

.user-box {
  text-align: center;

  .user-img {
    position: relative;
    // height: 88px;
    // width: 88px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 70px;
      // margin-right: 56px; //10px;
      // margin-left: 4px;
    }
  }

  h5 {
    a {
      color: $muted;
    }
  }

  .user-status {
    height: 12px;
    width: 12px;
    position: absolute;
    bottom: 7px;
    right: 15px;

    i {
      font-size: 15px;
    }
  }

  .user-status.away {
    i {
      color: $warning;
    }
  }

  .user-status.offline {
    i {
      color: $danger;
    }
  }

  .user-status.online {
    i {
      color: $success;
    }
  }

  .user-status.busy {
    i {
      color: $muted;
    }
  }

  ul {
    li {
      a {
        color: $muted;

        &:hover {
          color: $custom;
        }
      }
    }
  }
}

.notification-box {

  ul {
    max-height: 60px;

    li {
      a {
        font-size: 26px;
        color: $white;
        display: block;
        // line-height: 70px;
      }
    }
  }

  .noti-dot {
    position: relative;
    top: -45px;
  }

  .pulse {
    width: 2px;
    height: 2px;
    border: 3px solid $danger;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: $danger;
    z-index: 10;
    position: absolute;
    right: 6px;
  }

  .dot {
    border: 4px solid $danger;
    background: transparent;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 60px;
    height: 30px;
    width: 30px;
    -webkit-animation: pulse 3s ease-out;
    -moz-animation: pulse 3s ease-out;
    animation: pulse 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    position: absolute;
    top: -12px;
    left: 2px;
    z-index: 1;
    opacity: 0;
  }

  @-moz-keyframes pulse {
    0% {
      -moz-transform: scale(0);
      opacity: 0.0;
    }

    25% {
      -moz-transform: scale(0);
      opacity: 0.1;
    }

    50% {
      -moz-transform: scale(0.1);
      opacity: 0.3;
    }

    75% {
      -moz-transform: scale(0.5);
      opacity: 0.5;
    }

    100% {
      -moz-transform: scale(1);
      opacity: 0.0;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(0);
      opacity: 0.0;
    }

    25% {
      -webkit-transform: scale(0);
      opacity: 0.1;
    }

    50% {
      -webkit-transform: scale(0.1);
      opacity: 0.3;
    }

    75% {
      -webkit-transform: scale(0.5);
      opacity: 0.5;
    }

    100% {
      -webkit-transform: scale(1);
      opacity: 0.0;
    }
  }
}


.side-menu {
  top: 70px;
  width: $menu-width;
  // width: 290px;
  z-index: 10;
  background: $bg-leftbar;
  bottom: 0;
  margin-top: 0;
  padding-bottom: 30px;
  position: fixed;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);

  transition: linear;
  transition-duration: 300ms;

  @media (max-width:540px) {
    width: 100%;
  }

  .waves-effect {
    .waves-ripple {
      background-color: rgba($custom, 0.3);
    }
  }
}

.margin-bottom-zero {
  margin-bottom: 0px !important;
}

.margin-bottom-zero-mb {
  margin-bottom: 0 !important;
}

.margin-top {
  margin-top: 24px;
}

.multi-tabs {
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .tab-item {
    cursor: pointer;
    background: transparent;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    margin-right: 16px;
    height: 36px;
    padding: 0px 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
  }
}

.info-tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .tab-item-info {
    cursor: pointer;
    padding: 10px 0px;
    width: calc(100%/3.3);
    display: flex;
    justify-content: center;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .fa {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 2px 3px;
  }

  .fa-times {
    padding: 2px 4px;
  }
}

input::-webkit-input-placeholder {
  /* Edge */
  color: #85858F !important;
}

input :-ms-input-placeholder {
  /* Internet Explorer */
  color: #85858F !important;
}

input ::placeholder {
  color: #85858F !important;
}

.aml-details-card {
  display: flex;
  flex-direction: column;

  span {
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
  }

  .fa {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 2px 3px;
    margin-right: 10px;
  }

  .fa-times {
    padding: 2px 4px;
    color: #D84040 !important;
  }

  .fa-check {
    color: #00A510 !important;
  }
}

.button-global {
  .btn-primary-single {
    background-color: #177CDA !important;
    border: none !important;
    border-radius: 10px !important;
    color: #fff !important;
    min-width: 180px;
    height: 48px;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .btn-clear-link {
    color: #177CDA !important;
    background-color: transparent !important;
    font-weight: 500 !important;
    padding: 0 !important;
    border: none !important;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .btn-clear {
    background-color: transparent !important;
    border: 2px solid #177CDA !important;
    border-radius: 10px !important;
    color: #177CDA !important;
    min-width: 180px;
    height: 48px;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.processLength {
  position: absolute;
  top: 2% !important;
  left: 40% !important;

  .process-no {
    padding: 0 10px !important;
  }

  .active {
    color: #177CDA !important;
  }
}

.charts-scroll-bar {
  display: flex;
  align-items: center;
  min-height: calc(100% + 90px);
  max-height: calc(100% - 90px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }
}

.card-is-scrollbar {
  max-height: 100vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }
}

.card-is-not-scrollbar {
  max-height: unset !important;
  overflow: unset !important;
}


.do-form-group {
  margin: 0px 0px 24px 0px;
  height: 93px;

  label {
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    margin: 0px !important;
    margin-bottom: 12px !important;
    width: 100%;
  }

  input,
  select {
    width: 100%; //45%;
    text-align: left;
    height: 48px;
    border: none !important;
    border-radius: 10px;
    padding: 12px 20px;
  }

  select {
    text-align: left !important;
  }
}

// .do-form-group {
//   position: relative;
//   margin: 10px 0;
//   label {
//     position: absolute;
//     font-size: 16px;
//     font-weight: 400;
//     max-width: calc(100% - (2 * 6px));
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     left: 8px;
//     top: 13px;
//     padding: 0 8px;
//     transition: 250ms;
//     user-select: none;
//     pointer-events: none;

//   }
//   .input-1 {
//     box-sizing: border-box;
//     height: 50px;
//     width: 100%;
//     border-radius: 4px;
//     border: 1px solid #6a6a6a;
//     padding: 12px 15px;

//     transition: 250ms;
//     &:focus {
//       outline: none;
//       transition: 250ms;
//     }
//   }
// }
// .do-form-group.focus,
// .do-form-group.active {
//   label {
//     top: -10px;
//     font-size: 12px;
//     transition: 250ms;


//   }
// }
.do-form-group-radio {
  margin: 0px 0px 24px 0px;
  color: #85868F;
  font-size: 16px;
  padding: 12px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  label {
    font-size: 15px;
    font-weight: 400;
    margin: 0 !important;
  }

  .radio-button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .items {
      margin-right: 20px;

      input {
        margin-left: 8px;
      }
    }
  }
}

.do-modal-form-group {
  margin: 0px 0px 24px 0px;
  // border-radius: 10px;
  // padding: 12px 20px;
  color: #85868F;
  // background-color: #354650;
  position: relative;
  font-size: 16px;

  label {
    margin: 0px !important;
    margin-bottom: 24px !important;
  }

  input,
  select {
    // float: right;
    text-align: left; //end;
    border: none !important;
    background-color: #354650 !important;
    width: 100% !important; //45% !important;
    color: #85868F;
    height: 48px;
    border-radius: 10px;
    padding: 12px 20px;
  }

  select {
    text-align: center !important;
  }
}

.idv-score {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .idv-score-text {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    min-height: 100%;
  }
}

.info-primary-card {
  border-radius: 12px;
  min-height: 48px;
  padding: 11px 28px;
  min-width: 256px;
  max-width: 448px;

  &.no-max-width {
    max-width: unset !important;
    padding: 16px 24px !important;
  }

  &.no-min-width{
    min-width: unset !important;
  }
}

.circle-bar-progress {
  display: flex;
  flex-direction: row;
}

.form-container {
  margin: 0px 0px 24px 0px;
  border-radius: 10px;
  padding: 12px 20px;
  font-size: 16px;

  p {
    display: inline;
    max-width: 60%;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
  }

  span {
    float: right;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
  }
}

.activity-form-container {
  margin-bottom: 24px;
  background-color: transparent !important;

  p {
    margin-bottom: 12px !important;
    display: inline-block;
  }
}

.detail-form-container {
  margin: 0px 0px 24px 0px;
  background-color: transparent !important;

  p {
    margin-bottom: 12px !important;
    display: inline-block;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
  }

  h5 {
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
  }

  // hs
}

.aml-card-detail-container {
  font-size: 16px;
  margin: 0px 0px 8px 0px;
  background-color: transparent !important;

  p {
    margin-bottom: 8px !important;
    display: inline-block;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
  }

  h5 {
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
  }
}

.aml-card-container {
  font-size: 16px;
  margin: 0px 0px 24px 0px;
  background-color: transparent !important;

  p {
    margin-bottom: 12px !important;
    display: inline-block;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
  }

  h5 {
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
  }
}

.aml-detail-container {
  font-size: 16px;
  margin: 0px 0px 24px 0px;
  background-color: transparent !important;

  p {
    margin-bottom: 12px !important;
    display: inline-block;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
  }

  h5 {
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
  }
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.aml-form-container {
  font-size: 16px;
  margin: 0px 0px 16px 0px;
  background-color: transparent !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    margin-bottom: 12px !important;
    display: inline-block;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
  }

  h5 {
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
  }

  ul {
    margin-bottom: 0px !important;
  }

  ul {
    display: inline;
    float: right;

    li {
      list-style: none;
      margin-bottom: 16px;
      text-align: right;
      font-weight: 500;
    }
  }
}

.aml-achievements-container {
  font-size: 16px;
  margin: 0px 0px 16px 0px;
  background-color: transparent !important;

  ul {
    margin-bottom: 0px !important;
  }

  ul {
    display: inline;

    li {
      list-style: none;
      margin-bottom: 16px;
    }
  }
}


.risk-button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;

  button {
    margin-left: 20px;
  }

  .btn-onboard {
    background-color: #00A510 !important;
  }

  .btn-reject {
    background-color: #D84040 !important;
  }
}

.tabs-scroll {
  display: flex;

  .left-scroll {
    margin-top: 6px;
    margin-right: 20px;
    cursor: pointer;
  }

  .right-scroll {
    margin-left: 20px;
    margin-top: 6px;
    cursor: pointer;
  }
}

.table-status-badge {
  height: 36px;
  white-space: nowrap;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  border-radius: 12px;
  max-width: fit-content;
  background-color: #ED7D15;

  &.VERIFIED, &.APPROVED, &.LOW, &.SUCCESS{
    background-color: #0DB9A4 !important;
  }

  &.FAILED, &.REJECTED, &.HIGH{
    background-color: #D84040 !important;
  }

  &.ONBOARDED{
    background-color: #177CDA !important;
  }

  &.transparent-bg {
    font-family: $font-primary;
  }

  &.no-border {
    border: none !important;
  }
}

.risk-status-badge {
  height: 36px;
  white-space: nowrap;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  border-radius: 12px;
  max-width: fit-content;
  font-family: $font-secondary;
  font-size: 16px;
  line-height: 25.6px;
  letter-spacing: -0.32px;
  background-color: #ED7D15;

  &.VERIFIED, &.APPROVED, &.LOW, &.SUCCESS{
    background-color: #0DB9A4 !important;
  }

  &.FAILED, &.REJECTED, &.HIGH{
    background-color: #D84040 !important;
  }

  &.ONBOARDED{
    background-color: #177CDA !important;
  }
}


.exceed-tabs {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  scroll-behavior: smooth;
  align-items: center;
  flex-wrap: unset !important;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .tab-item {
    cursor: pointer;
    background: transparent;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    margin-right: 16px;
    height: 36px;
    padding: 0px 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}

// table,
// .table-bordered th,
// .table-bordered td {
//  // border: none !important;
// }
.table {
  border: none !important;
}

.table tbody tr td,
.table thead th {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}

.table td {
  padding: 0.25rem !important
}

.table-bordered th,
.table-bordered td {
  font-size: 16px;
  font-family: "Poppins" !important;
  overflow-x: auto;
  vertical-align: middle !important;
}

.nav-tabs,
.tab-content {
  border: none !important;
}

.max-height-unset {
  max-height: unset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-transition: background-color 9999s ease-out;
}

.back-with-description {
  display: flex;
  flex-direction: row;
  height: 48px;
  cursor: pointer;
  align-items: center;
  // width: fit-content;

  .close-arrow {
    margin-right: 20px;

    .fa {
      font-size: 35px;
      color: #177CDA !important;
      font-weight: 900 !important;
    }
  }
}

.questionaire {
  margin-top: 50px;
}

.dropdown-menu {
  a {
    display: flex;
    align-items: center;
  }
}

.table-bordered tr {
  vertical-align: middle !important;

  td {
    transition: all 0.3s ease-in-out !important;
  }

  .action {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .items {
      padding: 7px 12px;
      cursor: pointer;
      margin-right: 8px;
      width: max-content;
      border-radius: 5px;

      span {
        font-size: 16px;
        color: #85868F !important;
        margin-left: 12px;
      }

      .icons {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: transparent !important;
      }
    }
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0px 10px;
}

// .table {
//   td {
//     padding: 2px 0px;
//   }

//   th {
//     padding: 0.75rem 0px;
//   }
// }
.table tbody tr td {
  padding: 0.50rem
}

.table th {
  padding: 0.75rem 0.5rem;
}


.filteration {
  .filter-items {
    width: 100%;

    input {
      position: relative;
    }

    .fa-search {
      position: absolute;
      top: 16px;
      right: 20px;
      color: #85868F;
    }
  }
}


.content-page {
  margin-left: $menu-width;
  //margin-left: 290px;
  overflow: hidden;
  transition: ease-in-out;
  transition-duration: 300ms;

  .content {
    // margin-top: 76px;
    padding: 76px 16px 16px 16px !important;

 // redesign the layout
    flex-direction: column;
    display: flex;
    .container-fluid {
      padding: 0px !important;
      flex-direction: column;
      display: flex;
      flex: 1
    }
  }
}
.fill-page{
  padding: 0px !important;
  flex-direction: column;
  display: flex;
  flex: 1
}
.nav-section {
  margin-left: $menu-width;
  overflow: visible;
}


#wra .button-menu-mobile {
  background: transparent;
  border: none;
  color: rgba($white, 0.7);
  font-size: 21px;
  line-height: 68px;

  &:hover {
    color: $white;
  }
}

.sidebar-inner {
  height: $height;
}

#sidebar-menu,
#sidebar-menu ul,
#sidebar-menu li,
#sidebar-menu a {
  border: 0;
  font-weight: normal;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
}

#sidebar-menu {
  width: 100%;

  a {
    line-height: 1.3;
  }

  ul {
    li {
      .menu-arrow {
        -webkit-transition: -webkit-transform .15s;
        -o-transition: -o-transform .15s;
        transition: transform .15s;
        position: absolute;
        right: 20px;
        display: inline-block;
        text-rendering: auto;
        line-height: 18px;
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);

        &:before {
          content: '\f2fb';
        }
      }

      a.subdrop .menu-arrow {
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }

    ul {

      // display: none;
      li {
        border-top: 0;
      }

      li.active {
        a {
          color: #fff !important; //$custom;
        }
      }

      a {
        color: darken($muted, 10%);
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        border-left: 3px solid transparent;
        display: block;
        padding: 10px 20px 10px 65px;

        &:hover {
          color: #fff !important;
        }

        i {
          margin-right: 5px;
        }
      }

      ul {
        a {
          padding-left: 80px;
        }
      }
    }
  }

  .label {
    margin-top: 2px;
  }

  .subdrop {
    border-left: 3px solid $custom;
    // color: #fff !important; //$custom  !important;
  }
}


#sidebar-menu>ul>li>a {
  //   color: lighten($dark, 35%);
  display: block;
  // padding: 12px 20px;
  padding: 4px 16px;
  margin: 1px 0; //4px 0px;
  background-color: $bg-leftbar;
  border-left: 3px solid transparent;
  border-radius: 0 !important;

  &:hover {
    background: #5fb6f7 !important; //$lightdark  !important;
    text-decoration: none;
  }
}

#sidebar-menu>ul>li>a>span {
  vertical-align: middle;
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          display: inline-block;
          font-size: 16px;
          line-height: 17px;
          margin-left: 3px;
          margin-right: 0px;
          text-align: center;
          vertical-align: middle;
          width: 20px;
        }

        i.md {
          font-size: 18px;
        }

        .drop-arrow {
          float: right;

          i {
            margin-right: 0px;
          }
        }
      }

      .fa-angle-right {
        top: 14px !important;
        right: 4px !important;
      }

      .fa-angle-down {
        top: 14px !important;
        right: 4px !important;
      }
    }
  }
}


#sidebar-menu>ul>li>a.active {
  border-left: none; //3px solid $custom;
  // color: $custom  !important;
}


.menu-title {
  padding: 12px 20px !important;
  letter-spacing: .035em;
  pointer-events: none;
  cursor: default;
  font-size: 13px;
}

// #sidebar-menu ul ul {
//   display: none;
// }

#wrapper.enlarged {

  .menubutton {
    margin-right: 35px !important;
  }

  .icon-center {
    margin: 0 15px;
  }

  .logout-icon {
    margin: 50px 49px 10px 0px !important;
  }

  .menu-title,
  .menu-arrow {
    display: none !important;
  }

  .user-box {
    .user-img {
      // height: 48px;
      // width: 48px;

      img {
        width: 68px;
        margin-right: 10px !important;
        margin-left: 10px !important;
      }
    }

    h5 {
      display: none !important;
    }
  }

  #sidebar-menu {
    ul {
      ul {
        // border: 2px solid #f4f8fb;
        margin-top: -5px;
        // padding-top: 5px;
        z-index: 9999;
        // background-color: $white;
      }
    }
  }

  .left.side-menu {
    width: 103px; //70px;
    // z-index: 5;
    z-index: 9999;

    #sidebar-menu>ul>li>a {
      // padding: 15px 20px;
      padding: 15px 15px;

      &:hover {
        // background: #5fb6f7 !important; //$lightdark  !important;
      }

      &:active {
        // background: #5fb6f7 !important; //$lightdark  !important;
      }

      &:focus {
        // background: #5fb6f7 !important; //$lightdark  !important;
      }

      i {
        margin-right: -20px !important;
        font-size: 20px;
      }
    }

    .label {
      position: absolute;
      top: 5px;
      left: 35px;
      text-indent: 0;
      display: block !important;
      padding: .2em .6em .3em !important;
    }

    #sidebar-menu {
      ul>li {
        position: relative;
        white-space: nowrap;

        &:hover>a {
          position: relative;
          width: 280px;
          // background: $lightdark;
          // color: $custom;
          // border-color: $custom;
        }

        &:hover>ul {
          // display: block !important;
          // left: 70px;
          // position: absolute;
          // width: 190px;
          width: 210px;

          a {
            // background: $white;
            box-shadow: none;
            padding-left: 15px;
            position: relative;
            width: 186px;
            z-index: 6;

            &:hover {
              // color: $custom;
            }
          }
        }

        &:hover {
          a {
            span {
              // display: inline;
            }
          }
        }
      }

      li {
        .show-menu+ul {
          display: block;
          left: 70px;
          position: absolute;
          width: 190px;

          a {
            background: $white;
            box-shadow: none;
            padding-left: 15px;
            position: relative;
            width: 186px;
            z-index: 6;

            &:hover {
              color: $custom;
            }
          }

        }
      }

      a.subdrop {
        color: $custom !important;
      }

      ul>li>ul {
        // display: none !important;
      }

      ul {
        ul {
          li {
            &:hover>ul {
              display: block;
              left: 190px;
              margin-top: -36px;
              position: absolute;
              width: 190px;
            }
          }

          li>a {
            span.pull-right {
              -ms-transform: rotate(270deg);
              -webkit-transform: rotate(270deg);
              position: absolute;
              right: 20px;
              top: 12px;
              transform: rotate(270deg);
            }
          }

          li.active {
            a {
              color: $custom;
            }
          }
        }
      }

      ul>li>a {
        span {
          display: none;
          padding-left: 10px;
          transition: ease-in-out;
          transition-duration: 1s;

        }
      }
    }

    .user-details {
      display: none;
    }
  }

  .content-page,
  .nav-section {
    margin-left: 103px;
  }

  .footer {
    left: 70px;
  }

  .topbar {
    .topbar-left {
      width: 70px !important;

      .logo {
        span {
          display: none;
          opacity: 0;
        }

        i {
          display: block;
          // line-height: 70px;
          color: $dark !important;
        }
      }
    }

  }

  #sidebar-menu>ul>li {
    &:hover>a.open {
      :after {
        display: none;
      }
    }

    &:hover>a.active {
      :after {
        display: none;
      }
    }
  }

  .logoutText {
    display: none;
  }
}

#wrapper.right-bar-enabled {
  .right-bar {
    right: 0;
  }

  .left-layout {
    left: 0;
  }
}

/* Right sidebar */
.side-bar.right-bar {
  float: right !important;
  right: -266px;
  top: 0px;
}

.side-bar {
  -moz-transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  background-color: $white;
  box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  display: block;
  float: left;
  height: $height;
  position: fixed;
  transition: all 200ms ease-out;
  width: 240px;
}

.right-bar {
  background: $white !important;
  z-index: 999 !important;

  h4 {
    border-bottom: 1px solid rgba($muted, 0.5);
    padding: 4px 10px 10px 18px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: $dark;
    letter-spacing: 0.03em;
  }

  .right-bar-toggle {
    float: right;
    line-height: 46px;
    font-size: 20px;
    color: #333;
    padding: 0 10px;
  }
}

.notification-list {
  height: 100%;

  .list-group-item {
    border-bottom: 1px solid rgba($muted, 0.2) !important;
    margin-bottom: 0;
    background-color: $white;
  }

  .list-group-item.active {
    background-color: rgba($muted, 0.1);

  }
}

.user-list {
  .user-list-item {

    .avatar {
      float: left;
      margin-right: 5px;
      width: 30px;
      height: 30px;

      img {
        border-radius: 50%;
        width: $width;
      }
    }

    .icon {
      float: left;
      margin-right: 5px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      text-align: center;

      i {
        color: $white;
        line-height: 30px;
        font-size: 16px;
      }
    }

    .user-desc {
      margin-left: 40px;

      span.name {
        color: $dark;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        width: 100%;
        overflow: hidden;
      }


      span.desc {
        color: $muted;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        width: 100%;
        overflow: hidden;
        font-size: 12px;
      }

      span.time {
        font-size: 11px;
        color: $custom;
        font-style: italic;
      }
    }
  }
}

.transSlider {
  // display: none;
}

#wrapper.enlargedTransaction {
  .content-page {
    margin-right: 416px; //340px;
    transition: ease-in-out;
    transition-duration: 1s;

    @media (max-width:992px) {
      margin-right: 0;
    }
  }

  .transSlider {

    // display: block;
    .tr-pages {
      // width:416px;
      // transform: translateX(416px);
      right: 0 !important;
      margin-right: 0 !important;
      transition: ease-in-out;
      transition-duration: 1s;
    }
  }

  .insights {
    // flex-direction: column !important;

    .mainSection-1 {
      width: 100% !important;
      margin-bottom: 24px;
    }

    .mainSection-2 {
      width: 100% !important;
    }

    .sub-col-1 {
      width: 100%;

      canvas {
        height: 250px !important;
      }
    }

  }
}

/* Seach */
.app-search {
  position: relative;

  button {
    position: absolute;
    background-color: transparent;
    border: none;
    top: 4px;
    right: 8px;
    color: rgba($white, 0.7);

    &:hover {
      color: $white;
    }
  }

  .form-control,
  .form-control:focus {
    border: 1px solid rgba($white, 0.15);
    font-size: 13px;
    height: 34px;
    color: $white;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 40px;
    margin-top: 18px;
    margin-left: 20px;
    background: rgba($white, 0.1);
    box-shadow: none;
    border-radius: 30px;
    width: 190px;
  }
}

//input.app-search-input {
//  &::-webkit-input-placeholder {
//    color: rgba($white, 0.7);
//    font-weight: normal;
//  }
//  &:-moz-placeholder {
//    color: fade($white, 70%);
//  }
//  &::-moz-placeholder {
//    color: fade($white, 70%);
//  }
//  &:-ms-input-placeholder {
//    color: fade($white, 70%);
//  }
//}

.g-5 {
  gap: 5px !important;
}

.g-10 {
  gap: 10px !important;
}

.g-16 {
  gap: 16px !important;
}

.g-20 {
  gap: 20px !important;
}

.g-24 {
  gap: 24px !important;
}

.g-30 {
  gap: 30px !important;
}

.g-40 {
  gap: 40px !important;
}

.position-absolute {
  position: absolute !important;
}

.position-relative {
  position: relative !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-12 {
  border-radius: 12px !important;
}

.p-16 {
  padding: 16px !important;
  ;
}

.pre-badge {
  //used this class in UBO-report input-like content showing section.
  border-radius: 12px;
  padding: 5px 20px;
  overflow: hidden;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}