/* =============
   Helper clasess
============= */

.text-capital {
    text-transform: capitalize;
}

.text-underline {
    text-decoration: underline;
}

.text-center {
    text-align: center;
}

.text-upper {
    text-transform: uppercase;
}

.text-lower {
    text-transform: lowercase;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.m-center-auto {
    margin: 0 auto;
}

.d-block {
    display: block;
}

.p-0 {
    padding: 0 !important;
}

.p-20 {
    padding: 20px !important;
}

.p-30 {
    padding: 30px !important;
}

.p-l-0 {
    padding-left: 0 !important;
}

.p-r-0 {
    padding-right: 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-t-10 {
    padding-top: 10px !important;
}

.p-t-20 {
    padding-top: 20px !important;
}

.p-t-30 {
    padding-top: 30px !important;
}

.p-t-40 {
    padding-top: 40px !important;
}

.p-b-0 {
    padding-bottom: 0 !important;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-15 {
    padding-bottom: 15px !important;
}

.p-b-30 {
    padding-bottom: 30px !important;
}

.p-b-20 {
    padding-bottom: 20px !important;
}

.p-l-r-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.m-0 {
    margin: 0 !important;
}

.m-10 {
    margin: 10px !important;
}

.m-5 {
    margin: 5px !important;
}

.m-r-2 {
    margin-right: 2px !important;
}

.m-r-5 {
    margin-right: 5px !important;
}

.m-r-10 {
    margin-right: 10px !important;
}

.m-r-15 {
    margin-right: 15px !important;
}

.m-r-30 {
    margin-right: 30px !important;
}

.m-l-5 {
    margin-left: 5px !important;
}

.m-l-10 {
    margin-left: 10px !important;
}

.m-l-15 {
    margin-left: 15px !important;
}

.m-l-20 {
    margin-left: 20px !important;
}

.m-l-24 {
    margin-left: 1.5rem;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-15 {
    margin-top: 15px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-t-40 {
    margin-top: 40px !important;
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-15 {
    margin-bottom: 15px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

.w-xs {
    min-width: 80px;
}

.w-sm {
    min-width: 95px;
}

.w-md {
    min-width: 110px;
}

.w-lg {
    min-width: 140px;
}

.m-h-40 {
    min-height: 40px;
}

.m-h-50 {
    min-height: 50px;
}

.l-h-34 {
    line-height: 34px;
}

.font-normal {
    font-weight: normal;
}

.font-light {
    font-weight: 300;
}

.font-13 {
    font-size: 13px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-15 {
    font-size: 15px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-22 {
    font-size: 22px !important;
}

.font-25 {
    font-size: 25px !important;
}

.font-30 {
    font-size: 30px !important;
}

.wrapper-md {
    padding: 20px;
}

.pull-in {
    margin-left: -15px;
    margin-right: -15px;
}

.pull-in-card {
    margin-left: -20px !important;
    margin-right: -20px !important;
}

.b-0 {
    border: none !important;
}

.vertical-middle {
    vertical-align: middle;
}

.b-r-0 {
    border-radius: 0 !important;
}

.bx-shadow {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.mx-box {
    max-height: 380px;
    min-height: 380px;
}

.thumb-sm {
    height: 32px;
    width: 32px;
}

.thumb-md {
    height: 48px;
    width: 48px;
}

.thumb-lg {
    height: 88px;
    width: 88px;
}

.text-dark {
    color: black;
}

.upper-case {
    text-transform: uppercase;
}

.lower-case {
    text-transform: lowercase;
}

.cursor-pointer {
    cursor: pointer;
}

.h-44 {
    height: 44px !important;
}

.no-p {
    padding: 0px !important;
}

.no-m {
    margin: 0px !important;
}
.padding-card{
    padding: 16px 24px !important;
}
.padding-page{
    padding: 24px 24px 16px 24px !important;
}
.primary-section{
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    padding: 16px 24px;
}
.flex-col{
    display: flex;
    flex-direction: column;
}
.full-h{
    height: 100%;
}
.flex-1{
    flex: 1;
}
.flex-2{
    flex: 2;
}
.flex-3{
    flex: 3;
}
.fs-14{
font-size: 14px;
}
.g-12{
    gap:12px
}
.h-unset{
    height: unset !important;
}
.g-8{
    gap: 8px;
  }
  .g-56{
    gap:56px
  }