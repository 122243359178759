.accordian-card {
  background-color: transparent !important;

  &.collapsed {
    .fa-angle-up {
      transform: rotate(180deg);
      padding-bottom: 0px;
      margin-top: 9px !important;
    }
    .text-highlight{
      color: #177CDA !important;
    }
  }

  .lable-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 48px;
    cursor: pointer;
    align-items: center;
    .icon-i {
      color: #177CDA;
      margin: -1px 10px 5px 0px;

      .fa-angle-up {
        font-size: 26px;
        margin-top: 8px;
      }
    }
  }
}
