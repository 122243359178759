// Detail Page Tabs

.detail-page-tabs {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    scroll-behavior: smooth;
    align-items: center;
    flex-wrap: unset !important;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .tab-item {
        cursor: pointer;
        background: transparent;
        font-family: $font-secondary;
        font-size: 16px;
        line-height: 25.6px;
        letter-spacing: -0.32px;
        margin-right: 24px;
        white-space: nowrap;
        height: 48px;
        display: flex;
        align-items: center;
    }
}

.scroll-smooth {
  overflow-x: auto;
  scroll-behavior: smooth;
  
  &::-webkit-scrollbar {
      width: 0;
      height: 0;
  }
}

.table-scrollber{
  overflow-x: auto;
  scroll-behavior: smooth;
  
  &::-webkit-scrollbar {
      width: 0;
      height: 0;
  }
}

// The width and right values will be overridden by using '!important' tag. Can use it in the respected components and override the default values. 
.slider-container {
  position: relative;
  width: 27em; //total width's of every child.
  display: block !important;
  height: 2em;
  // margin: 100px auto;
  padding: 0;
  white-space: nowrap;
}

.slider-container li {
  display: inline;
  text-align: center;
}

.slider-container li a {
  position: relative;
  top: 0;
  left: 0;
  right: calc(27em - 6em); //total width minus first ele width eg: tot-width=51em first-ele-width=5em so, 51-5 = 46em
  bottom: 0;
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 8px;
  // color: #09C;
  text-decoration: none;
  // text-shadow: 0 1px 0 white;

  /*transition*/
  // -webkit-transition: width .3s ease-in-out,right .3s ease-in-out,color .3s ease-in-out;
  -moz-transition: width .3s ease-in-out,right .3s ease-in-out,color .3s ease-in-out;
  -o-transition: width .3s ease-in-out,right .3s ease-in-out,color .3s ease-in-out;
  // transition: width .3s ease-in-out,right .3s ease-in-out,color .3s ease-in-out;
}

.floating-button{
  position:fixed;
}

.slider-container li:nth-child(1) a { width: 6em; }
.slider-container li:nth-child(2) a { width: 9em; }
.slider-container li:nth-child(3) a { width: 6em; }
.slider-container li:nth-child(4) a { width: 5em; }
.slider-container li:nth-child(5) a { width: 1em; } // Don't change this value. And Let this be last element in any case

.slider-container li:last-child a::after {
  content: none;
  z-index: 1;
  position: absolute;
  right: inherit;
  bottom: -2px;
  width: inherit;
  height: 2px;
  background: rgba(204, 204, 204, 0.3);
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  // transition: all .5s ease-in-out;
}

.slider-container li:last-child a.active::after{
  content: "";
  // background: #177CDA;
}

.slider-container li:nth-child(1) ~ li:last-child a {
  right: calc(27em - (6em)); //using .slider-container li a "right"
  width: 6em;
}

.slider-container li:nth-child(2):hover ~ li:last-child a {
  right: calc(27em - (6em + 9em)); //45-9
  width: 9em;
}

.slider-container li:nth-child(3):hover ~ li:last-child a {
  right: calc(27em - (6em + 9em + 6em)); //36-8 etc....
  width: 6em;
}

.slider-container li:nth-child(4):hover ~ li:last-child a {
  right: calc(27em - (6em + 9em + 6em + 5em));
  width: 5em;
}

// Don't change this value.
.slider-container li:nth-child(5):last-child:hover a  {
  right: calc(27em - (6em + 9em + 6em + 5em + 1em));
  width:1em;
  pointer-events: none;
}

.slider-container li:hover ~ li:last-child a::after,
.slider-container li:last-child:hover a::after { background: #177CDA ;color: #177CDA !important; }
// Don't change this value.
.slider-container li:last-child a {
  min-width: 1em;
  max-width: 1em;
}

.slider-container li a:hover,
.slider-container li a:focus {
  color: #177CDA !important;
  background-color: transparent !important;

  /*transition*/
  -webkit-transition: width .3s ease-in-out,right .3s ease-in-out,background-color .3s ease-in-out,color .3s ease-in-out;
  -moz-transition: width .3s ease-in-out,right .3s ease-in-out,background-color .3s ease-in-out,color .3s ease-in-out;
  -o-transition: width .3s ease-in-out,right .3s ease-in-out,background-color .3s ease-in-out,color .3s ease-in-out;
  // transition: width .3s ease-in-out,right .3s ease-in-out,background-color .3s ease-in-out,color .3s ease-in-out;
}

.slider-container > li > a.active { border-bottom: 2px solid #177CDA !important ;z-index: 2;color: #177CDA !important ; }